import React, { FC } from 'react';

import { Props } from './MobileMenu.interface';
import { Root, Link, SocialLinks, SocialLink } from './MobileMenu.styled';
import Icon from 'src/modules/core/components/Icon';

const MobileMenu: FC<Props> = ({ isOpen, navLinks }) => (
  <Root isOpen={isOpen}>
    {navLinks.map((link, i) => (
      <Link key={i} href="#">{link.text}</Link>
    ))}
    <SocialLinks>
      <SocialLink href="https://facebook.com" target="_blank">
        <Icon name="facebook" />
      </SocialLink>
      <SocialLink href="https://instagram.com" target="_blank">
        <Icon name="instagram" />
      </SocialLink>
    </SocialLinks>
  </Root>
);

export default MobileMenu;
