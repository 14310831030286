import React, { FC, memo } from 'react';

import { Props } from './Button.interface';
import { Root } from './Button.styled';

const Button: FC<Props> = ({
  variant = 'contained',
  color = 'primary',
  textColor = '#fff',
  hoverEffect,
  children,
  className,
  onClick,
}) => (
  <Root
    variant={variant}
    color={color}
    hoverEffect={hoverEffect}
    textColor={textColor}
    className={className}
    onClick={onClick}
  >
    {children}
  </Root>
);

export default Button;
