import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image/index';

const query = graphql`
  query HowItWorksQuery {
    data: allHomeJson(
      filter: { type: { eq: "block" }, name: { eq: "howItWorks" } }
    ) {
      edges {
        node {
          title
          infoList {
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            description
          }
        }
      }
    }
  }
`;

interface HowItWorksData {
  title: string;
  infoList: {
    title: string;
    description: string;
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  }[];
}

const defaultData: HowItWorksData = {
  title: '',
  infoList: [],
};

const useHowItWorksData = (): HowItWorksData => {
  try {
    const response = useStaticQuery(query);

    return response.data.edges[0].node;
  } catch {
    return defaultData;
  }
};

export default useHowItWorksData;
