import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
// containers
import MobileMenu from 'src/modules/core/containers/MobileMenu';
// icons
import LogoTextIcon from 'src/static/icons/logoText.svg';

import { Props } from './Header.interface';
import {
  Root,
  Content,
  Navigation,
  Link,
  MobileMenuButton,
  MobileMenuPart,
  Logo,
  LogoIcon,
} from './Header.styled';

const Header: FC<Props> = ({ navLinks }) => {
  const rootRef = useRef<HTMLHeadElement>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const onScroll = useCallback(async () => {
    if (!rootRef.current) {
      return;
    }
    const rootStyle = rootRef.current.style;

    if (window.scrollY > 5 && rootStyle.paddingTop !== '10px') {
      rootStyle.paddingTop = '10px';
      rootStyle.boxShadow = '0px 10px 20px rgb(199 199 199 / 0.1)';
      rootStyle.opacity = '0.9';
    } else if (window.scrollY < 5 && rootStyle.paddingTop !== '20px') {
      rootStyle.paddingTop = '20px';
      rootStyle.boxShadow = 'unset';
      rootStyle.opacity = '1';
    }
  }, []);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const handleLinkPress = useCallback(
    (ref: RefObject<HTMLElement>) => () => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return (
    <>
      <Root ref={rootRef} isMobileMenuOpen={isMobileMenuOpen}>
        <Content>
          <Logo>
            <LogoIcon />
            <LogoTextIcon />
          </Logo>
          <Navigation>
            {navLinks.map((link, i) => (
              <Link
                key={i}
                data-hover={link.text}
                onClick={handleLinkPress(link.ref)}
              >
                {link.text}
              </Link>
            ))}
          </Navigation>
          <MobileMenuButton
            onClick={toggleMobileMenu}
            isActive={isMobileMenuOpen}
          >
            <MobileMenuPart isActive={isMobileMenuOpen} />
            <MobileMenuPart isActive={isMobileMenuOpen} />
            <MobileMenuPart isActive={isMobileMenuOpen} />
          </MobileMenuButton>
        </Content>
      </Root>
      <MobileMenu navLinks={navLinks} isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />
    </>
  );
};

export default Header;
