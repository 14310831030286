import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
`;

export const Item = styled.button<{ isActive: boolean }>`
  position: relative;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  height: 55px;
  padding: 0 30px;
  font-size: 20px;
  color: #747474;
  margin-bottom: 6px;
  transition: 0.3s;

  &:before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #a0a0a1;
    border-radius: 50%;
    transition: 0.1s;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isActive
      ? `
        box-shadow: 0 10px 20px rgba(199, 199, 199, 0.18);
        color: #4b4b4b;
        
        &:before {
          background-color: #d1b359;
          width: 10px;
          height: 10px;
        }
      `
      : ''}
`;
