import React, { FC } from 'react';

import { Props } from './Tabs.interface';
import { Root, Item } from './Tabs.styled';

const Tabs: FC<Props> = ({ activeTab, list, onChange }) => {
  const handleTabPress = (key: string | number) => () => {
    onChange(key);
  };

  return (
    <Root>
      {list.map((tab) => (
        <Item
          key={tab.key}
          isActive={activeTab === tab.key}
          onClick={handleTabPress(tab.key)}
        >
          {tab.text}
        </Item>
      ))}
    </Root>
  );
};

export default Tabs;
