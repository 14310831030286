import styled from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';
import Img from 'gatsby-image';

export const Root = styled.div`
  overflow-x: hidden;
  height: 720px;
  padding-bottom: 100px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -1.22%,
    #f2f5f6 100%
  );
  mask-image: url(${require('src/static/images/healthIndexBlockMask.svg')});
  mask-size: cover;
  mask-position: center bottom;
  mask-repeat: no-repeat;

  ${(props) => props.theme.media.tablet`
    padding-bottom: 100px;
    height: unset;
  `}

  ${(props) => props.theme.media.mobile`
    padding-bottom: 0;
    mask-position: -500px bottom;
  `}
  
  @media (max-width: 540px) {
    mask-position: -800px bottom;
  }
`;

export const Content = styled(GlobalContent)`
  position: relative;
  display: flex;
  height: 100%;

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
  `}
`;

export const Info = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 97px;

  ${(props) => props.theme.media.tablet`
    max-width: 100%;
    width: 100%;
    margin-right: unset;
  `}
`;

export const Title = styled.h2`
  ${(props) => props.theme.media.tablet`
    text-align: center;
  `}
`;

export const Description = styled.p`
  margin-top: 30px;

  ${(props) => props.theme.media.tablet`
    text-align: center;
    margin-top: 20px;
  `}
`;

export const Decorations = styled.div`
  position: relative;
  flex: 1;

  ${(props) => props.theme.media.tablet`
    min-height: 531px;
  `}
`;

export const IphoneWrapper = styled.div`
  position: absolute !important;
  top: -40px;
  z-index: 1;

  ${(props) => props.theme.media.tablet`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const IphoneImage = styled(Img).attrs({
  src: require('src/static/images/iphone2.png'),
})`
  width: 506px;

  ${(props) => props.theme.media.tablet`
    width: 379px;
  `}
`;

export const DecorationCircle = styled.div`
  position: absolute;
  top: 105px;
  right: 10px;
  width: 480px;
  height: 480px;
  background-color: #e4e4e4;
  border-radius: 50%;

  ${(props) => props.theme.media.tablet`
    width: 360px;
    height: 360px;
  `}

  ${(props) => props.theme.media.mobile`
    left: -10px;
  `}
`;
