import React, { FC, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import MobileDetect from 'mobile-detect';
// animations
import { onHTMLElementEnterOnWindow } from 'src/modules/core/animations/scroll';
// icons
import AppleStoreIcon from 'src/static/icons/appleStore.svg';
import GoogleStoreIcon from 'src/static/icons/googlePlay.svg';

import {
  Root,
  Content,
  Board,
  Title,
  Description,
  Actions,
  Button,
  ButtonText,
  ButtonBigText,
  CropBlock,
} from './DownloadApp.styled';
import { appearanceOfText } from 'src/modules/core/animations/text';

const DownloadApp: FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const cropBlockRef = useRef<HTMLDivElement>(null);
  const [os, setOs] = useState<null | string>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    const md = new MobileDetect(window.navigator.userAgent);
    setOs(md.os());
  }, []);

  useEffect(() => {
    if (cropBlockRef.current && rootRef.current) {
      onHTMLElementEnterOnWindow(cropBlockRef.current, () => {
        gsap.to(cropBlockRef.current, {
          duration: 1,
          width: 0,
          ease: 'power4.out',
        });

        if (rootRef.current) {
          rootRef.current.querySelectorAll('.animatedText').forEach((node) => {
            appearanceOfText([node as HTMLElement], 0.4);
          });

          rootRef.current
            .querySelectorAll('.animatedActions')
            .forEach((node) => {
              gsap.from(node, {
                duration: 0.6,
                opacity: 0,
                delay: 0.8,
              });
            });
        }
      });
    }
  }, []);

  return (
    <Root ref={rootRef}>
      <Content>
        <Board>
          <Title className="animatedText">
            Start Using HealthPlate App Now!
          </Title>
          <Description className="animatedText">
            Data Collection ipsum dolor sit amet, consectetur adipiscing elit.
          </Description>
          <Actions className="animatedActions">
            {os !== 'AndroidOS' && (
              <Button>
                <AppleStoreIcon />
                <ButtonText>
                  Download on the <ButtonBigText>App Store</ButtonBigText>
                </ButtonText>
              </Button>
            )}
            {os !== 'iOS' && (
              <Button>
                <GoogleStoreIcon />
                <ButtonText>
                  Download on the <ButtonBigText>Google Play</ButtonBigText>
                </ButtonText>
              </Button>
            )}
          </Actions>
          <CropBlock ref={cropBlockRef} />
        </Board>
      </Content>
    </Root>
  );
};

export default DownloadApp;
