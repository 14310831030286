import React, {FC, RefObject, useCallback, useLayoutEffect, useRef, useState} from 'react';
// containers
import VideoPopup from 'src/modules/core/containers/VideoPopup';
// hooks
import useAboutData from 'src/modules/cms/hooks/home/useAboutData';
// animations
import { appearanceOfTextFromHide } from 'src/modules/core/animations/text';

import {
  Root,
  Content,
  LeftBlock,
  RightBlock,
  Title,
  Description,
  Actions,
  FruitsImage,
  IPhoneImage,
  DownButton,
  Bubble,
  DownloadButton,
  WatchVideoButton,
  IconWrapper,
  PlayIcon,
  VideoButtonText,
} from './About.styled';
import { graphql, useStaticQuery } from 'gatsby';

type IAboutProps = {
  downloadRef: RefObject<HTMLElement>;
}

const About: FC<IAboutProps> = ({downloadRef}) => {
  const assets = useStaticQuery(assetsQuery);
  const data = useAboutData();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const videoIconRef = useRef<HTMLDivElement>(null);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleDownloadPress = useCallback(() => {
    if (downloadRef.current) {
      window.scrollTo({
        top:
          window.scrollY +
          downloadRef.current.getBoundingClientRect().top -
          (window.innerHeight - downloadRef.current.getBoundingClientRect().height) /
          2 +
          50,
        behavior: 'smooth',
      });
    }
  },[downloadRef])

  useLayoutEffect(() => {
    if (titleRef.current && descriptionRef.current) {
      appearanceOfTextFromHide([titleRef.current, descriptionRef.current]);
    }
  }, [titleRef, descriptionRef]);

  const toggleVideoPopup = useCallback(() => {
    setIsVideoOpen(!isVideoOpen);
  }, [isVideoOpen]);

  const handleDownClick = useCallback(() => {
    window.scrollTo({ top: window.innerHeight - 50, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Root>
        <Content>
          <LeftBlock>
            <Title ref={titleRef}>{data.title}</Title>
            <Description ref={descriptionRef}>{data.description}</Description>
            <Actions>
              <DownloadButton onClick={handleDownloadPress}>Download</DownloadButton>
              <WatchVideoButton onClick={toggleVideoPopup}>
                <IconWrapper ref={videoIconRef}>
                  <PlayIcon />
                </IconWrapper>
                <VideoButtonText>Watch video</VideoButtonText>
              </WatchVideoButton>
            </Actions>
          </LeftBlock>
          <RightBlock>
            <IPhoneImage fluid={assets.phone.childImageSharp.fluid} />
            <FruitsImage fluid={assets.fruits.childImageSharp.fluid} />
          </RightBlock>
        </Content>
        <DownButton onClick={handleDownClick}>
          <Bubble />
          <Bubble />
          <Bubble />
          <Bubble />
        </DownButton>
      </Root>
      <VideoPopup
        isOpen={isVideoOpen}
        onClose={toggleVideoPopup}
        triggerRef={videoIconRef}
      />
    </>
  );
};

const assetsQuery = graphql`
  query {
    fruits: file(relativePath: { eq: "fruits.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    phone: file(relativePath: { eq: "iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default About;
