import styled from 'styled-components';

export const Root = styled.div``;

export const AccordionItem = styled.div`
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionHeaderText = styled.h4<{ isActive: boolean }>`
  font-size: 20px;
  color: ${(props) => (props.isActive ? '#4b4b4b' : '#747474')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 0.3s;
`;

export const AccordionBody = styled.div`
  overflow: hidden;
  transition: 0.3s;
`;

export const AccordionBodyWrapper = styled.div``;

export const ActionIcon = styled.div<{ isActive: boolean }>`
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: 10px;

  &:before {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #d1a972;
    border-radius: 1px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    content: '';
    width: 2px;
    height: 100%;
    background-color: #d1a972;
    border-radius: 1px;
    transition: 0.3s;
    transform: ${(props) => (props.isActive ? 'rotate(90deg)' : 'rotate(0)')};
  }
`;
