import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query HealthIndexQuery {
    data: allHomeJson(
      filter: { type: { eq: "block" }, name: { eq: "healthIndex" } }
    ) {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;

interface HealthIndexData {
  title: string;
  description: string;
}

const defaultData: HealthIndexData = {
  title: '',
  description: '',
};

const useHealthIndexData = (): HealthIndexData => {
  try {
    const response = useStaticQuery(query);

    return response.data.edges[0].node;
  } catch {
    return defaultData;
  }
};

export default useHealthIndexData;
