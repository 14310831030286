import React, { FC, memo } from 'react';
// icons
import ArrowLeftIcon from 'src/static/icons/arrowLeft.svg';
import MinusIcon from 'src/static/icons/minus.svg';
import PlusIcon from 'src/static/icons/plus.svg';
import FacebookIcon from 'src/static/icons/facebook.svg';
import InstagramIcon from 'src/static/icons/instagram.svg';
import PlayIcon from 'src/static/icons/play.svg';
import CloseIcon from 'src/static/icons/close.svg';
import StarIcon from 'src/static/icons/star.svg';

import { Props, IconsMap } from './Icon.interface';

const icons: IconsMap = {
  arrowLeft: ArrowLeftIcon,
  minus: MinusIcon,
  plus: PlusIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  play: PlayIcon,
  close: CloseIcon,
  star: StarIcon,
};

const Icon: FC<Props> = ({ name, ...props }) => {
  const IconElement = icons[name];

  if (!IconElement) {
    console.warn(`Icon with name: "${name}" does not exist.`);
    return null;
  }

  return <IconElement {...props} />;
};

export default memo(Icon);
