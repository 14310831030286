import styled from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';

export const Root = styled.div``;

export const Content = styled(GlobalContent)``;

export const Title = styled.h2`
  text-align: center;
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  padding-top: 35px;
  min-height: 666px;
  height: 666px;
`;

export const TabsColumn = styled.div`
  width: 360px;

  @media (max-width: 1180px) {
    width: 300px;
  }
`;

export const TabInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40%;
`;

export const IphoneImage = styled.img`
  height: 100%;
  width: auto;

  @media (max-width: 1180px) {
    height: 600px;
  }

  ${(props) => props.theme.media.mobile`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    height: unset;
    width: 202px;
  `}
`;

export const TextColumn = styled.div`
  width: 360px;

  @media (max-width: 1180px) {
    width: 300px;
  }
`;

export const TabTitle = styled.h3`
  color: #4b4b4b;
`;

export const TabDescription = styled.p`
  margin-top: 30px;
`;

export const AccordionWrapper = styled.div`
  margin-top: 20px;
`;

export const AccordionItemContent = styled.div`
  padding-top: 20px;
`;

export const AccordionItemText = styled.p``;
