import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query AboutQuery {
    about: allHomeJson(
      filter: { type: { eq: "block" }, name: { eq: "about" } }
    ) {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;

interface AboutData {
  title: string;
  description: string;
  phone: string | null;
}

const defaultData: AboutData = {
  title: '',
  description: '',
  phone: '',
};

const useAboutData = (): AboutData => {
  try {
    const response = useStaticQuery(query);

    return response.about.edges[0].node;
  } catch {
    return defaultData;
  }
};

export default useAboutData;
