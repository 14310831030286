import styled, { Colors } from 'styled-components';

import { ButtonHoverEffect, ButtonVariants } from './Button.interface';

export const Root = styled.button<{
  variant: ButtonVariants;
  color: Colors | string;
  textColor: Colors | string;
  hoverEffect?: ButtonHoverEffect;
}>`
  display: flex;
  align-items: center;
  padding: 0 33px;
  height: 50px;
  border-radius: 25px;
  width: fit-content;
  color: ${(props) => props.theme.palette[props.textColor] ?? props.textColor};

  // variant
  ${(props) =>
    props.variant === 'contained'
      ? `
        background-color: ${props.theme.palette[props.color] ?? props.color};
      `
      : ''}

  // hover effect
  ${(props) => {
    if (props.hoverEffect) {
      if (props.hoverEffect.type === 'transition') {
        return `
          transition: ${props.hoverEffect.transitionDuration ?? 200}ms;
          &:hover {
            background-color: ${
              props.theme.palette[props.hoverEffect.transitionToColor] ??
              props.hoverEffect.transitionToColor
            };
          }
        `;
      }
    }
    return '';
  }}
`;
