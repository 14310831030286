import React, { FC, SVGProps, useEffect, useRef, useMemo } from 'react';
import { ScrollTrigger } from 'gsap/all';
import gsap from 'gsap';
// hooks
import useFeatureData from 'src/modules/cms/hooks/home/useFeatureData';
// animations
import {
  appearanceOfText,
} from 'src/modules/core/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/modules/core/animations/scroll';
// icons
import AnalysisIcon from 'src/static/icons/analysis.svg';
import AwardIcon from 'src/static/icons/award.svg';
import GrowthIcon from 'src/static/icons/growth.svg';
import RickyContentIcon from 'src/static/icons/riskyContent.svg';
import BookIcon from 'src/static/icons/book.svg';
import AnalysisStarIcon from 'src/static/icons/analysisStar.svg';

import {
  Root,
  Content,
  Title,
  Description,
  Blocks,
  Block,
  BlockIconWrapper,
  BlockName,
  BlockDescription,
} from './Feature.styled';

gsap.registerPlugin(ScrollTrigger);

interface Feature {
  title: string;
  description: string;
  Icon: FC<SVGProps<{}>>;
}

const Feature: FC = () => {
  const data = useFeatureData();
  const rootRef = useRef<HTMLDivElement>(null);

  const listOfFeatures = useMemo<Feature[]>(
    () => [
      {
        title: data.list.analysis.title,
        description: data.list.analysis.description,
        Icon: AnalysisIcon,
      },
      {
        title: data.list.recommendations.title,
        description: data.list.recommendations.description,
        Icon: AwardIcon,
      },
      {
        title: data.list.progress.title,
        description: data.list.progress.description,
        Icon: GrowthIcon,
      },
      {
        title: data.list.articles.title,
        description: data.list.articles.description,
        Icon: RickyContentIcon,
      },
      {
        title: data.list.recipes.title,
        description: data.list.recipes.description,
        Icon: BookIcon,
      },
      {
        title: data.list.rate.title,
        description: data.list.rate.description,
        Icon: AnalysisStarIcon,
      },
    ],
    [data]
  );

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.querySelectorAll('.animatedBlockIcon').forEach((node) => {
        onHTMLElementEnterOnWindow(node, () => {
          gsap.from(node, {
            opacity: 0,
            duration: 1,
          });
        });
      });
      rootRef.current
        .querySelectorAll('.textAnimatedAppearance')
        .forEach((node) =>
          onHTMLElementEnterOnWindow(node, () => {
            appearanceOfText([node as HTMLElement]);
          })
        );
    }
  }, []);

  return (
    <Root ref={rootRef}>
      <Content>
        <Title className="textAnimatedAppearance">{data.title}</Title>
        <Description className="textAnimatedAppearance">
          {data.description}
        </Description>
        <Blocks>
          {listOfFeatures.map((feature, i) => (
            <Block key={i}>
              <BlockIconWrapper className="animatedBlockIcon">
                <feature.Icon />
              </BlockIconWrapper>
              <BlockName className="textAnimatedAppearance">
                {feature.title}
              </BlockName>
              {
                feature.description && (
                  <BlockDescription className="textAnimatedAppearance">
                    {feature.description}
                  </BlockDescription>
                )
              }
            </Block>
          ))}
        </Blocks>
      </Content>
    </Root>
  );
};

export default Feature;
