import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as generateId } from 'uuid';
// hooks
import useHowItWorksData from 'src/modules/cms/hooks/home/useHowItWorks';
// animations
import { onHTMLElementEnterOnWindow } from 'src/modules/core/animations/scroll';
import { appearanceOfText } from 'src/modules/core/animations/text';
// components
import Tabs from 'src/modules/core/components/Tabs';
import Accordion from 'src/modules/core/components/Accordion';
// types
import { TabData } from 'src/modules/core/components/Tabs/Tabs.interface';

import {
  Root,
  Title,
  Content,
  TabsWrapper,
  TabsColumn,
  TabInfo,
  IphoneImage,
  TextColumn,
  TabTitle,
  TabDescription,
  AccordionWrapper,
  AccordionItemContent,
  AccordionItemText,
} from './HowItWorks.styled';
import { FluidObject } from 'gatsby-image/index';

const TabsView: FC<{
  list: {
    title: string;
    description: string;
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  }[];
}> = ({ list }) => {
  const tabs = useMemo<TabData[]>(
    () =>
      list.map((item, index) => ({
        key: index,
        text: item.title,
      })),
    [list]
  );
  const [activeTab, setActiveTab] = useState<string | number>(tabs[0].key);
  const activeTabData = useMemo(() => list[activeTab as number], [
    list,
    activeTab,
  ]);

  return (
    <TabsWrapper>
      {
        tabs.length > 1 && (
          <TabsColumn>
            <Tabs activeTab={activeTab} list={tabs} onChange={setActiveTab} />
          </TabsColumn>
        )
      }
      <TabInfo>
        <IphoneImage src={activeTabData.image.childImageSharp.fluid.src} />
      </TabInfo>
      <TextColumn>
        <TabTitle>{activeTabData.title}</TabTitle>
        <TabDescription>{activeTabData.description}</TabDescription>
      </TextColumn>
    </TabsWrapper>
  );
};

const AccordionView: FC<{
  list: {
    title: string;
    description: string;
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  }[];
}> = ({ list }) => {
  const items = useMemo(() => {
    return list.map((item) => ({
      title: item.title,
      content: (
        <AccordionItemContent>
          <AccordionItemText>{item.description}</AccordionItemText>
          <IphoneImage src={item.image.childImageSharp.fluid.src} />
        </AccordionItemContent>
      ),
    }));
  }, [list]);

  return (
    <AccordionWrapper>
      <Accordion items={items} />
    </AccordionWrapper>
  );
};

const HowItWorks: FC = () => {
  const data = useHowItWorksData();
  const rootRef = useRef<HTMLDivElement>(null);
  const [isAccordion, setIsAccordion] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 1024) {
      setIsAccordion(true);
    }
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current
        .querySelectorAll('.textAnimatedAppearance')
        .forEach((node) =>
          onHTMLElementEnterOnWindow(node, () => {
            appearanceOfText([node as HTMLElement]);
          })
        );
    }
  }, []);

  return (
    <Root ref={rootRef}>
      <Content>
        <Title className="textAnimatedAppearance">{data.title}</Title>
        {isAccordion ? (
          <AccordionView list={data.infoList} />
        ) : (
          <TabsView list={data.infoList} />
        )}
      </Content>
    </Root>
  );
};

export default HowItWorks;
