import styled from 'styled-components';

export const Root = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  z-index: 999;
  transform: ${(props) => (props.isOpen ? 'scale(1)' : 'scale(0)')};
  transition: 0.2s;
  transform-origin: top right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.a`
  font-size: 22px;
  line-height: 25px;
  color: #454545;
  margin: 15px 0;
`;

export const SocialLinks = styled.div`
  margin-top: 15px;
`;

export const SocialLink = styled.a`
  padding: 10px;
`;
