import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { Props, ItemProps } from './Accordion.interface';
import {
  Root,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  AccordionHeaderText,
  AccordionBodyWrapper,
  ActionIcon,
} from './Accordion.styled';

const Item: FC<ItemProps> = ({ index, onToggle, title, content, isActive }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentHeight = useMemo<number>(
    () => contentRef.current?.clientHeight ?? 0,
    [contentRef.current]
  );

  const onPress = useCallback(() => {
    onToggle(index);
  }, [index, onToggle]);

  return (
    <AccordionItem key={index}>
      <AccordionHeader onClick={onPress}>
        <AccordionHeaderText isActive={isActive}>{title}</AccordionHeaderText>
        <ActionIcon isActive={isActive} />
      </AccordionHeader>
      <AccordionBody style={{ height: isActive ? contentHeight : 0 }}>
        <AccordionBodyWrapper ref={contentRef}>{content}</AccordionBodyWrapper>
      </AccordionBody>
    </AccordionItem>
  );
};

const Accordion: FC<Props> = ({ items }) => {
  const [activeTabIndex, setActiveTagIndex] = useState<number | null>(null);

  const handleTabClick = useCallback(
    (index: number) => {
      if (index === activeTabIndex) {
        setActiveTagIndex(null);
      } else {
        setActiveTagIndex(index);
      }
    },
    [activeTabIndex]
  );

  return (
    <Root>
      {items.map((item, index) => (
        <Item
          key={index}
          index={index}
          isActive={index === activeTabIndex}
          onToggle={handleTabClick}
          {...item}
        />
      ))}
    </Root>
  );
};

export default Accordion;
