import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query FeatureQuery {
    about: allHomeJson(
      filter: { type: { eq: "block" }, name: { eq: "feature" } }
    ) {
      edges {
        node {
          title
          description
          list {
            recipes {
              title
              description
            }
            recommendations {
              title
              description
            }
            progress {
              title
              description
            }
            articles {
              title
              description
            }
            rate {
              title
              description
            }
            analysis {
              title
              description
            }
          }
        }
      }
    }
  }
`;

type FeatureListFields =
  | 'recipes'
  | 'recommendations'
  | 'progress'
  | 'articles'
  | 'rate'
  | 'analysis';

type FeatureList = {
  [key in FeatureListFields]: {
    title: string;
    description: string;
  };
};

interface FeatureData {
  title: string;
  description: string;
  list: FeatureList;
}

const defaultData: FeatureData = {
  title: '',
  description: '',
  list: {
    recipes: {
      title: '',
      description: '',
    },
    recommendations: {
      title: '',
      description: '',
    },
    progress: {
      title: '',
      description: '',
    },
    articles: {
      title: '',
      description: '',
    },
    rate: {
      title: '',
      description: '',
    },
    analysis: {
      title: '',
      description: '',
    },
  },
};

const useFeatureData = (): FeatureData => {
  try {
    const response = useStaticQuery(query);

    return response.about.edges[0].node;
  } catch {
    return defaultData;
  }
};

export default useFeatureData;
