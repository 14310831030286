import styled from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';
import LogoSVG from 'src/static/icons/logo.svg';

export const Root = styled.footer`
  height: 111px;

  ${(props) => props.theme.media.mobile`
    height: unset;
  `}
`;

export const Content = styled(GlobalContent)`
  height: 100%;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border-top: 1px solid rgba(196, 196, 196, 0.5);

  ${(props) => props.theme.media.mobile`
    flex-direction: column;
    padding: 30px 0 20px 0;
  `}
`;

export const Copyright = styled.span`
  font-size: 12px;
  color: #c4c4c4;
`;

export const NavLinks = styled.nav`
  display: flex;

  ${(props) => props.theme.media.mobile`
    flex-direction: column;
    align-items: center;
    margin: 30px 0 40px 0;
  `}
`;

export const NavLink = styled.a`
  font-size: 12px;
  color: #454545;
  margin: 0 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.theme.media.tablet`
    margin-bottom: 20px;
    
    :last-child {
      margin-bottom: 0;
    }
  `}
`;

export const SocialLinks = styled.nav`
  ${(props) => props.theme.media.tablet`
    margin-bottom: 20px;
  `}
`;

export const SocialLink = styled.a`
  padding: 9px;
  fill: #c4c4c4;
  transition: 0.5s;

  &:hover {
    fill: ${(props) => props.theme.palette.primary};
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  ${(props) => props.theme.media.mobile`
    flex-direction: column;
  `}
`;

export const LogoIcon = styled(LogoSVG)`
  margin-right: 10px;

  ${(props) => props.theme.media.mobile`
    margin-right: 0;
    margin-bottom: 15px;
  `}
`;

export const SeediumLink = styled.a`
  color: #1f2426;
`;

export const Dot = styled.span`
  color: #ff4747;
`;
