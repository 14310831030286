import styled, { keyframes } from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';
import Button from 'src/modules/core/components/Button';
import Icon from 'src/modules/core/components/Icon';
import Img from 'gatsby-image';

export const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;

  ${(props) => props.theme.media.tablet`
    height: unset;
  `}
`;

export const Content = styled(GlobalContent)`
  display: flex;
  height: calc(100% - 81px);
  padding-top: 81px;

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
    padding-top: 0;
    height: 100%;
  `}
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  width: 600px;
  opacity: 1;

  ${(props) => props.theme.media.laptop`
    width: 500px;
  `}

  ${(props) => props.theme.media.tablet`
    width: 100%;
    padding-top: 105px;
  `}
`;

export const Title = styled.h1`
  margin-bottom: 20px;

  ${(props) => props.theme.media.tablet`
    text-align: center;
  `}
`;

export const Description = styled.p`
  max-width: 480px;
  line-height: 28px;
  font-size: 17px;

  ${(props) => props.theme.media.tablet`
    text-align: center;
    margin: 0 auto;
  `}
`;

const actionsAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 35px;
  opacity: 0;
  animation: actionsAnimation;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;

  ${(props) => props.theme.media.tablet`
    justify-content: center;
  `}
`;

export const RightBlock = styled.div`
  position: relative;
  flex: 1;

  ${(props) => props.theme.media.tablet`
    min-height: 640px;
  `}

  ${(props) => props.theme.media.mobile`
    min-height: 477px;
  `}
`;

const showFruitsAnimation = keyframes`
  0% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(1);
  }
`;

export const FruitsImage = styled(Img)`
  position: absolute !important;
  left: 70px;
  z-index: 0;
  width: 953px;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: center;
  animation: ${showFruitsAnimation};
  animation-duration: 1s;

  ${(props) => props.theme.media.mobile`
    width: 714px;
    left: 0;  
  `}
`;

const phoneImageAnimation = keyframes`
  0% {
    transform: translateY(-50%) translateX(100px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-50%) translateX(0);
    opacity: 1;
  }
`;

export const IPhoneImage = styled(Img)`
  position: absolute !important;
  top: 50%;
  left: 140px;
  width: 270px;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  animation: ${phoneImageAnimation};
  animation-duration: 1s;
  animation-delay: .3s;
  animation-fill-mode: forwards;

  ${(props) => props.theme.media.mobile`
    width: 202px;
    left: calc(50% - 101px);
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`;

const downButtonAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DownButton = styled.button`
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  animation: ${downButtonAnimation};
  opacity: 0;
  animation-duration: 0.3s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  width: 40px;
  height: 40px;

  & > div:nth-child(1) {
    width: 3px;
    height: 3px;
    animation-delay: 0.1s;
  }

  & > div:nth-child(2) {
    width: 5px;
    height: 5px;
    animation-delay: 0.2s;
  }
  & > div:nth-child(3) {
    width: 7px;
    height: 7px;
    animation-delay: 0.3s;
  }
  & > div:nth-child(4) {
    width: 9px;
    height: 9px;
    animation-delay: 0.4s;
  }

  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`;

const blink = keyframes`
  from {
    opacity: 0.4;
  }
  
  to {
    opacity: 1;
  }
`;

export const Bubble = styled.div`
  border-radius: 50%;
  background-color: #e2e2e2;
  animation-name: ${blink};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

export const DownloadButton = styled(Button).attrs({
  color: 'primary3',
  hoverEffect: {
    type: 'transition',
    transitionToColor: '#E8CA61',
  },
})``;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(-50%, -50%);
`;

export const PlayIcon = styled(Icon).attrs({
  name: 'play',
})``;

export const WatchVideoButton = styled(Button).attrs({
  color: 'transparent',
  textColor: '#555555',
})`
  position: relative;
  margin-left: 30px;
  padding-left: 60px;

  &::before {
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    background: #faf7f2;
    z-index: -1;
  }

  &:hover::before {
    width: 100%;
  }

  &:after {
    content: '';
    width: 50px;
    height: 50px;
    z-index: -1;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: 0.3s;
  }

  &:hover::after {
    transition-delay: 0.5s;
    background: rgba(224, 222, 217, 0.41);
  }

  ${(props) => props.theme.media.mobile`
    padding: 0;
    width: 50px;
    background-color: #FAF7F2;
    margin-left: 50px;
    
    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.65;
      border: 1px solid #FAF7F2;
      background-color: rgba(250, 247, 242, 0.5);
      width: 72px;
      height: 72px;
    }
    
    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.45;
      border: 1px solid #FAF7F2;
      background-color: rgba(250, 247, 242, 0.2);
      width: 94px;
      height: 94px;
    }
    
    &:hover::before {
      width: 72px;  
    }
    
    &:hover::after {
      background-color: rgba(250, 247, 242, 0.2);
    }
  `}
`;

export const VideoButtonText = styled.span`
  ${(props) => props.theme.media.mobile`
    display: none;
  `}
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% + 50px);
`;
