import React, { FC, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { graphql, useStaticQuery } from 'gatsby';
// hooks
import useHealthIndexData from 'src/modules/cms/hooks/home/useHealthIndexData';
// animations
import { onHTMLElementEnterOnWindow } from 'src/modules/core/animations/scroll';
import { appearanceOfText } from 'src/modules/core/animations/text';

import {
  Root,
  Content,
  Info,
  Title,
  Description,
  Decorations,
  DecorationCircle,
  IphoneWrapper,
  IphoneImage,
} from './HealthIndex.styled';

const HealthIndex: FC = () => {
  const assets = useStaticQuery(assetsQuery);
  const data = useHealthIndexData();
  const rootRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current
        .querySelectorAll('.textAnimatedAppearance')
        .forEach((node) =>
          onHTMLElementEnterOnWindow(node, () => {
            appearanceOfText([node as HTMLElement]);
          })
        );

      if (phoneRef.current) {
        onHTMLElementEnterOnWindow(phoneRef.current, () => {
          gsap.from(phoneRef.current, {
            left: 200,
            opacity: 0,
            duration: 1.6,
            ease: 'power4.out',
          });
        });
      }
    }
  }, []);

  return (
    <Root ref={rootRef}>
      <Content>
        <Info>
          <Title className="textAnimatedAppearance">{data.title}</Title>
          <Description className="textAnimatedAppearance">
            {data.description}
          </Description>
        </Info>
        <Decorations>
          <IphoneWrapper ref={phoneRef}>
            <IphoneImage fluid={assets.phone.childImageSharp.fluid} />
          </IphoneWrapper>
          <DecorationCircle />
        </Decorations>
      </Content>
    </Root>
  );
};

const assetsQuery = graphql`
  query {
    phone: file(relativePath: { eq: "iphone2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default HealthIndex;
