import { TimelineMax, TimelineLite } from 'gsap';
import { v4 as generateId } from 'uuid';
import 'src/services/greenSock/SplitText.min';

function appearanceOfText(textNodes: HTMLElement[], delay: number = 0) {
  const animationId = `animation${generateId().replace(/-/g, '')}`;

  textNodes.forEach((node) => {
    new SplitText(node, {
      type: 'lines',
      linesClass: `${animationId} singleLine`,
    });
  });

  new TimelineLite().from(`.${animationId}`, 0.8, { opacity: 0, delay: delay });
  new TimelineMax().staggerFrom(`.${animationId}`, 0.6, { y: 40, delay }, 0.1);
}

function appearanceOfTextFromHide(textNodes: HTMLElement[], delay: number = 0) {
  const animationId = `animation${generateId().replace(/-/g, '')}`;

  textNodes.forEach((node) => {
    new SplitText(node, {
      type: 'lines',
      linesClass: 'noOverflow singleLine',
    });

    node.childNodes.forEach((subNode) => {
      new SplitText(subNode as HTMLElement, {
        type: 'lines',
        linesClass: animationId,
      });
    });
  });

  const tl = new TimelineMax();
  tl.staggerFrom(`.${animationId}`, 0.6, { y: 50, delay }, 0.1);
}

export { appearanceOfText, appearanceOfTextFromHide };
