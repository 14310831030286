import React, { FC } from 'react';
// icons
import LogoTextIcon from 'src/static/icons/logoText.svg';
import FacebookIcon from 'src/static/icons/facebook.svg';
import InstagramIcon from 'src/static/icons/instagram.svg';

import {
  Root,
  Content,
  FooterContent,
  Copyright,
  NavLinks,
  NavLink,
  SocialLinks,
  SocialLink,
  Logo,
  LogoIcon,
  Dot,
  SeediumLink,
} from './Footer.styled';

const Footer: FC = () => (
  <Root>
    <Content>
      <FooterContent>
        <Logo>
          <LogoIcon />
          <LogoTextIcon />
        </Logo>
        <NavLinks>
          <NavLink href="#">Home</NavLink>
          <NavLink href="#">Pricing</NavLink>
          <NavLink href="#">FAQ</NavLink>
          <NavLink href="/pdf/termsOfService.pdf" target="_blank">
            Terms & Conditions
          </NavLink>
          <NavLink href="/pdf/privacyPolicy.pdf" target="_blank">
            Privacy
          </NavLink>
        </NavLinks>
        <SocialLinks>
          <SocialLink href="#">
            <FacebookIcon fill="inherit" />
          </SocialLink>
          <SocialLink href="#">
            <InstagramIcon fill="inherit" />
          </SocialLink>
        </SocialLinks>
        <Copyright>
          © 2020 HealthPlate. All rights reserved.{' '}
          <SeediumLink href="https://seedium.io" target="_blank">
            Seedium<Dot>.</Dot>
          </SeediumLink>
        </Copyright>
      </FooterContent>
    </Content>
  </Root>
);

export default Footer;
