import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import gsap from 'gsap';
// components
import Icon from 'src/modules/core/components/Icon';

import { Props } from './Popup.interface';
import { Root, Shadow, Content, CloseButton } from './Popup.styled';

const modalsRootId = 'modals-root';

const Popup: FC<Props> = ({ isOpen, onClose, triggerRef, children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);

  const getRoot = () => {
    const existRoot = document.getElementById(modalsRootId);

    if (existRoot) {
      return existRoot;
    }

    const root = document.createElement('div');
    root.id = modalsRootId;
    document.body.appendChild(root);
    return root;
  };

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const triggerRect = triggerRef.current
      ? triggerRef.current.getBoundingClientRect()
      : { left: 0, top: 0 };

    if (isOpen) {
      document.body.style.overflow = 'hidden';

      gsap.fromTo(
        contentRef.current,
        {
          left: triggerRect.left,
          top: triggerRect.top,
          scale: 0,
        },
        {
          left: '50%',
          top: '50%',
          scale: 1,
          duration: 0.5,
          ease: 'power4.out',
        }
      );
      setLocalIsOpen(true);
    } else {
      document.body.style.overflow = 'unset';

      gsap.fromTo(
        contentRef.current,
        {
          left: '50%',
          top: '50%',
          scale: 1,
        },
        {
          left: triggerRect.left,
          top: triggerRect.top,
          scale: 0,
          duration: 0.3,
          ease: 'power4.out',
          onComplete: () => {
            setLocalIsOpen(false);
          },
        }
      );
    }
  }, [isOpen, contentRef]);

  if (isOpen || localIsOpen) {
    return ReactDOM.createPortal(
      <Root>
        <Content ref={contentRef}>
          {children}
          <CloseButton onClick={onClose}>
            <Icon name="close" />
          </CloseButton>
        </Content>
        <Shadow onClick={onClose} isOpen={isOpen} />
      </Root>,
      getRoot()
    );
  }

  return null;
};

export default Popup;
