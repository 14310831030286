import React, { FC } from 'react';
// components
import Popup from 'src/modules/core/components/Popup';

import { Props } from './VideoPopup.interface';
import { Root, Video } from './VideoPopup.styled';

const VideoPopup: FC<Props> = ({ ...props }) => (
  <Popup {...props}>
    <Root>
      <Video>
        <source src={require('src/static/videos/main.mp4')} type="video/mp4" />
      </Video>
    </Root>
  </Popup>
);

export default VideoPopup;
