import { DefaultTheme, css } from 'styled-components';

export type DeviceSizes = 'mobile' | 'tablet' | 'laptop';

type MediaSizes = {
  [key in DeviceSizes]: number;
};

const mediaSizes: MediaSizes = {
  mobile: 767,
  tablet: 1023,
  laptop: 1439,
};

export const createMediaQuery = Object.keys(mediaSizes).reduce<any>(
  (acc, label: string) => {
    acc[label] = (...args: any[]) => css`
      // @ts-ignore
      @media (max-width: ${mediaSizes[label]}px) {
        // @ts-ignore
        ${css(...args)};
      }
    `;
    return acc;
  },
  {}
);

const theme: DefaultTheme = {
  palette: {
    primary: '#C5A54D',
    primary2: '#E9B869',
    primary3: '#EED373',
    secondary: '#F2EBD8',
  },
  media: createMediaQuery,
};

export default theme;
