import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
  width: 80vw;
  background-color: #fff;
  padding-top: 56%;
`;

export const Video = styled.video.attrs({
  controls: true,
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
`;
