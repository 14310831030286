import React, { FC, useRef } from 'react';
// components
import PageLayout from 'src/modules/core/containers/PageLayout';
import Header from 'src/modules/core/containers/Header';

import About from 'src/sections/Landing/About';
import Feature from 'src/sections/Landing/Feature';
import HealthIndex from 'src/sections/Landing/HealthIndex';
import DownloadApp from 'src/sections/Landing/DownloadApp';
import Comments from 'src/sections/Landing/Comments';
import HowItWorks from 'src/sections/Landing/HowItWorks';

const Landing: FC = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const featureRef = useRef<HTMLDivElement>(null);
  const healthIndexRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const downloadRef = useRef<HTMLDivElement>(null);

  return (
    <PageLayout>
      <Header
        navLinks={[
          { text: 'About', ref: aboutRef },
          { text: 'Feature', ref: featureRef },
          { text: 'HealthPlate Index', ref: healthIndexRef },
          { text: 'How it works', ref: howItWorksRef },
          { text: 'Download App', ref: downloadRef },
        ]}
      />
      <div ref={aboutRef}>
        <About downloadRef={downloadRef} />
      </div>
      <section ref={featureRef}>
        <Feature />
      </section>
      <section ref={healthIndexRef}>
        <HealthIndex />
      </section>
      <section ref={howItWorksRef}>
        <HowItWorks />
      </section>
      {/* TODO: temporary hidden by client's request*/}
      {/*<section>*/}
      {/*  <Comments />*/}
      {/*</section>*/}
      <section ref={downloadRef}>
        <DownloadApp />
      </section>
    </PageLayout>
  );
};

export default Landing;
