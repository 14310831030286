import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

export type Target = Element | string;

function onHTMLElementEnterOnWindow(target: Target, onEnter: () => void) {
  ScrollTrigger.create({
    trigger: target,
    start: 0,
    once: true,
    onEnter,
  });
}

export { onHTMLElementEnterOnWindow };
