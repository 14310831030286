import styled, { keyframes } from 'styled-components';

export const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

const shadowAnimation = keyframes`
  from {
    opacity: 0;
  } 
  to {
    opacity: 0.6;
  }
`;

export const Shadow = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  transform: translate(-50%, -50%);
  animation: ${shadowAnimation};
  animation-duration: 1s;
  transition: 0.3s;
  opacity: ${(props) => (props.isOpen ? '0.6' : '0')};
`;

export const Content = styled.div`
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 3px;
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: 100%;
  left: 100%;
  padding: 0;
`;
