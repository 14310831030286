import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import Helmet from 'react-helmet';
// components
import Footer from 'src/modules/core/containers/Footer';
// hooks
import useMetaData from 'src/modules/cms/hooks/home/useMetaData';
// style
import theme from 'src/style/theme';
import GlobalStyle from 'src/style/globalStyle';

import { Props } from './PageLayout.interface';

// const query = graphql`
//   query LayoutQuery {
//     site {
//       siteMetadata {
//         languages {
//           defaultLangKey
//           langs
//         }
//       }
//     }
//   }
// `;

const PageLayout: FC<Props> = ({ children }) => {
  const { title, description, content } = useMetaData();

  return (
    <React.Fragment>
      <Helmet
        title={title}
        defaultTitle={title}
        meta={[{ name: description, content }]}
      />
      <IntlProvider locale={'en'}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {children}
          <Footer />
        </ThemeProvider>
      </IntlProvider>
    </React.Fragment>
  );
};

export default PageLayout;
