import styled from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';

export const Root = styled.div``;

export const Content = styled(GlobalContent)``;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;

  ${(props) => props.theme.media.mobile`
    margin-bottom: 20px;
  `}
`;

export const Description = styled.p`
  max-width: 860px;
  text-align: center;
  margin: 0 auto;
`;

export const Blocks = styled.div`
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-top: 66px;

  ${(props) => props.theme.media.tablet`
    width: 48%;
  `}

  ${(props) => props.theme.media.mobile`
    width: 100%;
    margin-top: 40px;
  `}
`;

export const BlockIconWrapper = styled.div`
  height: 64px;
`;

export const BlockName = styled.h4`
  color: #4b4b4b;
  text-align: center;
  margin-top: 15px;
`;

export const BlockDescription = styled.p`
  text-align: center;
  margin-top: 16px;

  ${(props) => props.theme.media.mobile`
    margin-top: 8px;
  `}
`;
