import styled, { keyframes } from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';
import LogoSVG from 'src/static/icons/logo.svg';

const rootAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
`;

export const Root = styled.header<{
  isMobileMenuOpen: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fff;
  z-index: 1000;
  transition: 0.5s;
  opacity: 0;
  backdrop-filter: saturate(180%) blur(12px);
  animation: ${rootAnimation};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  ${(props) =>
    props.isMobileMenuOpen
      ? `
        background-color: #F1F1F1;
        box-shadow: unset;
      `
      : ''}

  ${(props) => props.theme.media.mobile`
    padding-top: 10px;
  `}
`;

export const Content = styled(GlobalContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
`;

export const Navigation = styled.nav`
  display: flex;

  ${(props) => props.theme.media.mobile`
    display: none;
  `}
`;

export const Link = styled.a`
  user-select: none;
  background-color: transparent;
  text-decoration: none;
  border: none;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: #454545;
  cursor: pointer;

  &:last-child {
    padding-right: 0;
  }

  position: relative;
  display: inline-block;
  padding: 10px 0;
  margin: auto 15px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 10px 0 5px 0;
    max-width: 0;
    color: #b89843;
    content: attr(data-hover);
    transition: max-width 0.5s;
    border-bottom: 1px solid #b89843;
    white-space: nowrap;
  }

  &:hover ::before {
    max-width: 100%;
  }
`;

export const MobileMenuButton = styled.button<{ isActive: boolean }>`
  position: relative;
  display: none;
  padding: 10px;
  width: 23px;
  height: 17px;
  transition: 0.2s;

  ${(props) => props.theme.media.mobile`
    display: block;
  `}

  ${(props) =>
    props.isActive
      ? `
        transform: rotate(180deg);
      `
      : ''}
`;

export const MobileMenuPart = styled.div<{ isActive: boolean }>`
  position: absolute;
  right: 0;
  left: 0;
  background-color: #c4c4c4;
  margin: auto;
  width: 18px;
  height: 3px;
  transition: all 0.49s cubic-bezier(0.4, 0, 0.2, 1.9);

  &:nth-of-type(1) {
    top: 0;
    width: 23px;

    ${(props) =>
      props.isActive
        ? `
          transform: rotate(45deg);
          width: 26px !important;
          top: calc(50% - (2px))!important;`
        : ''}
  }

  &:nth-of-type(2) {
    top: 7px;
    width: 23px;

    ${(props) =>
      props.isActive
        ? `
          left: 11px !important;
          opacity: 0;`
        : ''}
  }

  &:nth-of-type(3) {
    top: 14px;
    width: 23px;

    ${(props) =>
      props.isActive
        ? `
          transform: rotate(-45deg);
          width: 26px!important;
          top: calc(50% - (2px))!important;`
        : ''}
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoIcon = styled(LogoSVG)`
  margin-right: 10px;
`;
