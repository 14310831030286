import styled from 'styled-components';
import { Content as GlobalContent } from 'src/style/globalStyle';

export const Root = styled.div`
  padding-bottom: 100px;

  ${(props) => props.theme.media.mobile`
    padding-bottom: 60px;
  `}
`;

export const Content = styled(GlobalContent)``;

export const Board = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 15px 70px 15px;
  background: linear-gradient(73.62deg, #b89843 0%, #eccf70 100%);
  border-radius: 20px;

  ${(props) => props.theme.media.mobile`
    padding: 40px 15px;
  `}
`;

export const Title = styled.h2`
  color: #fff;
  text-align: center;
`;

export const Description = styled.h4`
  max-width: 500px;
  text-align: center;
  color: #fff;
  margin-top: 20px;

  ${(props) => props.theme.media.tablet`
    font-size: 18px;
  `}
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 36px;

  ${(props) => props.theme.media.mobile`
    flex-direction: column;
  `}
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  margin: 0 15px;
  transition: 0.3s;

  &:hover {
    background-color: #b89843;

    & > span {
      color: #fff;
    }

    & > svg {
      transition: 0.3s;
      fill: #fff;
    }
  }

  ${(props) => props.theme.media.mobile`
    margin-bottom: 20px;
    
    :last-child {
      margin-bottom: 0;
    }
  `}
`;

export const ButtonText = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  width: 95px;
  text-align: left;
  margin-left: 12px;
  color: #d0b158;
`;

export const ButtonBigText = styled.span`
  font-size: 16px;
`;

export const CropBlock = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
`;
