import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query MetaQuery {
    data: allHomeJson(filter: { type: { eq: "block" }, name: { eq: "meta" } }) {
      edges {
        node {
          title
          description
          author
          content
        }
      }
    }
  }
`;

export interface MetaData {
  title: string;
  description: string;
  author: string;
  content: string;
}

const defaultData: MetaData = {
  title: '',
  description: '',
  author: '',
  content: '',
};

const useMetaData = (): MetaData => {
  try {
    const response = useStaticQuery(query);

    return response.data.edges[0].node;
  } catch {
    return defaultData;
  }
};

export default useMetaData;
